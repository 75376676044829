import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Comingsoon from "components/hero/comingsoon";
import Hero from "components/hero/blog07hero.js";

import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/NT_mainpagefooter";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Comingsoon/>
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
