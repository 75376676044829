import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/NT_mainpagefooter";
import MainFeature1 from "components/features/NT_aboutusfirstfeature";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import DownloadApp from "components/cta/DownloadApp.js";
import ContactForm from "components/forms/testform";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactForm/>
      <MainFeature1
        subheading={<Subheading>About Niraj</Subheading>}
        heading="Mortgage Advisor for over 12 Years."
        buttonRounded={false}
        primaryButtonText="Contact Info"
        description="Through my many years of experience I've had the oppurtunity to work at numerous financial institutions to gain experience, knowledge, and critical skills. I've served the communities of Brampton, Mississauga, Georgetown, Toronto, and more. Over the years I've had the privellege of working with many clients and helping them through the mortgage process. Let me help you get the mrotgage you deserve, by using my experience and skills to get you the best rates, terms, and conditions."
      />
      <MainFeature1
        subheading={<Subheading>My Vision</Subheading>}
        heading="Getting you the property you deserve."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://source.unsplash.com/TiVPTYCG_3E"
        textOnLeft={false}
        description="Whether it be a primary home, rental property, or investment property, I can help you purchase whatever real-estate you want. As a mortgage advisor, my work revolves around your mortgage needs and wants. I can personally work with you to structure your financing deals, that fit your preferences!"
      />
      <Features
        subheading={<Subheading>Core Values</Subheading>}
        heading="We follow these. To help you."
        description="My goal is to help you purchase your dream property, while guiding you through the process and helping you overcome any obstacles. After years of experience, training, and learning I've been able to offer my clients phenomenal service and support for all of their financing needs."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Support",
            description: "No matter what  the question or hesitation may be, rest assured that I will work tirelessly to help solve your problems and answer your questions."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Fast",
            description: "With an average start to finish time of 2 days, getting your mortgage is both fast and easy."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "After serving many clients throughout the community, I've recieved lots of feedback and comments to offer all of my clients the best service possible, to meet your needs."
          },
        ]}
        linkText=""
      />
      <DownloadApp
        text={<>To get started on your mortgage deal <HighlightedTextInverse>contact me today.</HighlightedTextInverse></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
