import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/NT_hero.js";
import Features from "components/features/NT_features.js";
import MainFeature from "components/features/NT_photoright";
import MainFeature2 from "components/features/NT_valuesprinceiplesfeature";
import FeatureWithSteps from "components/features/NT_featurewithsteps";
import Pricing from "components/pricing/NT_ratepricing3";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/NT_mainpagefooter";
import FeatureStats from "components/features/NT_featurestats";
import pic1 from "images/moving-your-mortgage.svg"

import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import pic2 from "images/cibc_home.jfif"
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Focused on Creating <HighlightedText>Customer Value.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={pic1}
        imageBorder={false}
        imageDecoratorBlob={true}
      />
      <FeatureStats/>
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={pic2}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that we can offer you the best service for purchasing your next real-estate property.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
            <Pricing
        subheading={<Subheading>CURRENT MORTGAGE RATES</Subheading>}
        heading={
          <>
            Variable & Fixed <HighlightedText>Rates.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Fixed Rate Closed",
            price: "4.34% Insured",
            duration: "5 Year Term",
            mainFeature: "5.09% Conventional",
            features: ["The security of a fixed interest rate, so you always know exactly what your payments will be."]
          },
          {
            name: "Variable Flex Mortgage",
            price: "2.98% Insured",
            duration: "5 Year Term",
            mainFeature: "3.13% Conventional",
            features: ["A low variable interest rate with the flexibility of annual prepayments of up to 20% without paying a prepayment charge."],
            featured: true
          },
          {
            name: "Fixed Rate Closed",
            price: "3.59% Insured",
            duration: "1 Year Term",
            mainFeature: " 3.59% Conventional",
            features: ["The security of a fixed interest rate, so you always know exactly what your payments will be."]
          }
        ]}
      />
      
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "How can you qualify ?",
            answer:
              "As the housing market continues to grow every year, it is now important more than ever that you qualify for the best mortgage options available. Some key attributes that are important to your approval are holding a good credit score, having low debt, and holding a strong employment/income history."
          },
          {
            question: "How long does the process take ?",
            answer:
              "From start to finish the financing process around 5 days. This time varied depending on the complexity of the financing terms, the conditions, and many other factors."
          },
          {
            question: "How can you offer me the lowest rate possible ?",
            answer:
              "CIBC has maintained its position as one of the largest banks in Canada by offering it's customers the best rats on the market. I work tirelessly to ensure you're provided with the best terms and rates for your mortgage. If you find a better rate elsewhere, we can even price-match the exact rate to provide you with the best service on the market!"
          },
          {
            question: "What can I do if I have a poor credit score ?",
            answer:
              "If your credit score is holding you back, you can try to improve your credit score by either reducing your debts, clearing collection items, and more. However, credit score is not the only factor that is considered by the lender. If you have any questiosn regarding the effects of your score or your own financing deal, please contact me."
          },
          {
            question: "Are there any special programs for first time homebuyers ?",
            answer:
              "First-time homebuyers, qualify for many programs/incentives and are provided with many benefits to help them attain their first mortgage. My experience and knowledge can help all first-time homebuyers navigate through this tricky journey to help them purchase their first home. To learn more about the many benefits offered to first-time homebuyers please give me a call."
          },
          {
            question: "What is the minimum down payment required to purchase a home ?",
            answer:
              "The minimum down payment required to purchase a home is 5%, however this number is unique to each client and can change depending on different financing situations."
          },
          {
            question: "What is a pre-approved mortgage ?",
            answer:
              "Pre-approved mortgages are provided by lenders to certain clients after doing an initial review of the client's income, credit, and down-payment. A pre-approved mortgage can help a client get an early indication of their price range/budget, terms, and financing conditions. If you'd like to learn more about pre-approved mortgages, or would like to recieve a pre-approval through CIBC, please contact me to get started."
          }
        ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
