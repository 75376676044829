import React from "react";
import tw from "twin.macro";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Pricing from "components/pricing/NT_ratepricing3";
import Footer from "components/footers/NT_mainpagefooter";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import Hero from "components/hero/mortgagerateshero.js";
import Offers from "components/features/DashedBorderSixFeatures"
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;
export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Pricing
        subheading={<Subheading>CURRENT MORTGAGE RATES</Subheading>}
        heading={
          <>
            Variable & Fixed <HighlightedText>Rates.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Fixed Rate Closed",
            price: "5.09% Insured",
            duration: "5 Year Term",
            mainFeature: "5.19% Conventional",
            features: ["The security of a fixed interest rate, so you always know exactly what your payments will be."]
          },
          {
            name: "Variable Flex Mortgage",
            price: "4.88% Insured",
            duration: "5 Year Term",
            mainFeature: "4.95% Conventional",
            features: ["A low variable interest rate with the flexibility of annual prepayments of up to 20% without paying a prepayment charge."],
            featured: true
          },
          {
            name: "Fixed Rate Closed",
            price: "5.09% Insured",
            duration: "1 Year Term",
            mainFeature: " 5.09% Conventional",
            features: ["The security of a fixed interest rate, so you always know exactly what your payments will be."]
          }
        ]}
      />
      <Offers/>
      <Blog/>
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
  );
};
