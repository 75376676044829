import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/click.svg";
import ShieldIconImage from "../../images/click.svg";
import CustomizeIconImage from "../../images/click.svg";


const Container = tw.div`relative`;
const Subbheading = tw.span` tracking-widest font-bold`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Mortgage + HELOC",
      description: "Utilize the equity in your house and combine all of your personal debt into a single, low-interest secured loan."
    },
    { imageSrc: SupportIconImage, title: "Wealth Builder Mortgage",
    description: "With cash back, you can pay off your debt while accumulating savings." },
    { imageSrc: CustomizeIconImage, title: "Cash Back Offer",
    description: "Get a cash back worth up to $5300 on your mortgage deal depending on the volume and length of the mortgage." },
    {
      imageSrc: ShieldIconImage,
      title: "Rebate",
      description: "Get a $300.00 rebate towards property appraisal fee"
    },
    { imageSrc: SupportIconImage, title: "Lawyer Fees",
    description: "Get up to $ 300.00 towards lawyer fee for mortgages above $500,000" },
    { imageSrc: CustomizeIconImage, title: "Mortgage Transfer Cash Back",
    description: "Transfer your mortgage to CIBC and get a generous cash back offer" },

  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Current Offers</Heading>
        <Subbheading>To learn more about our current offers and other benefits you may be eligible for, please contact me.</Subbheading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
